$primary-color: #00965e;
$secondary-color: #36ab80;
$primary-text-color: white;
$nav-color: #00965e;
$sidebar-color: #385f43;
$hover-color: #014e32;
$highlight-color: #d4ece3;
$dark-grey: #666666;
$danger: #ef4444;
$danger-hover: #a21818;

$bookmark-1: #5cb85c;
$bookmark-2: #f0ad4e;
$bookmark-3: #d9534f;
$bookmark-4: #c24ef0;
$bookmark-5: #367896;

$warning-color: #f0ad4e;
$danger-color: #d9534f;

:root {
    // override ng prime colors
    --primary-color: #00965e !important;
    // ovvertide d-library colors
    --d-dark-bg: #00965e !important;
    --d-primary-color: #00965e !important;
}
