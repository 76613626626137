.node-configuration-pages {
    padding-bottom: 2rem;
    .card {
        cursor: default !important;
        .header-title {
            font-weight: 600;
            font-size: 1.2rem;
            margin-bottom: 1.2rem;
        }
        .header-description {
            font-weight: 400;
            font-size: 1.2rem;
            margin-bottom: 1.6rem;
        }
        .form-group {
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
            .title {
                font-weight: 600;
                font-size: 1.2rem;
            }
            .p-field-checkbox {
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
                .notification-name {
                    margin-bottom: 0px;
                    margin-left: 1rem;
                    position: relative;
                    i {
                        position: absolute;
                        margin-left: 0.5rem;
                        top: -2px;
                    }
                }
                p-checkbox {
                    .p-checkbox-box {
                        &.p-highlight {
                            border-color: $primary-color;
                            background: $primary-color;
                            &:hover {
                                border-color: $hover-color;
                                background: $hover-color;
                            }
                        }
                    }
                }
                .waiting-msg {
                    margin-left: 3rem;
                }
            }
            p-dropdown {
                .p-dropdown {
                    width: 25rem;
                    .p-inputtext {
                        font-size: 1rem;
                    }
                }
            }
            .btn-save {
                width: 7rem;
                background: $primary-color;
                border: 1px solid $primary-color;
                color: #fff;
                border-radius: 4px;
                box-shadow: none !important;
                font-size: 1rem;
                cursor: pointer;
                padding: 5px 2px;
                display: inline-block;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                line-height: 1.5;
                i {
                    margin-right: 0.8rem;
                }
                &:hover {
                    background-color: $hover-color;
                    border: 1px solid $hover-color;
                }
                &:disabled {
                    cursor: default;
                    opacity: 0.65;
                    &:hover {
                        background: $primary-color;
                        border: 1px solid $primary-color;
                        opacity: 0.5;
                    }
                }
                p-progressSpinner {
                    .custom-spinner .p-progress-spinner-circle {
                        stroke: #0000ff !important;
                    }
                }
            }
        }
    }
}
