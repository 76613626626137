p-password {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    .p-password {
        position: relative !important;
        display: inline-flex !important;
    }
    &.ng-invalid input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin: 0;
    }
}
