.update-manager-card {
    .node-card {
        background: #fff;
        padding: 1rem;
        margin-bottom: 2rem;
        box-shadow: 0 3px 3px -2px #0000001a, 0 3px 4px #00000012, 0 1px 8px #0000000f;
        .breadcrumb-box {
            ::ng-deep {
                .p-breadcrumb {
                    padding: 0.5rem !important;
                    margin-bottom: 1rem !important;
                    background: #eee;
                }
            }
        }
    }
}

:host {
    ::ng-deep {
        .p-breadcrumb {
            ul {
                li {
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
