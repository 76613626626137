// Admin MyTrend component css
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../node_modules/primeng/resources/primeng.min.css";
@import "../../../node_modules/quill/dist/quill.core.css";
@import "../../../node_modules/quill/dist/quill.snow.css";
@import "../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
/* You can add global styles to this file, and also import other style files */

// Admin component scss
@import "./custom.scss";
@import "./_p-password.scss";
@import "./_utilities.scss";
@import "./components/components";

html,
body {
  height: 100%;
  overflow: hidden;
}

//Well

.well {
  min-height: 2rem;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 0.3rem;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  &-scrollable {
    padding: 2rem 0rem 0rem 0rem;
  }
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-lg {
  padding: 2rem;
  border-radius: 0.5rem;
}
.well-sm {
  padding: 1rem;
  border-radius: 0.4rem;
}
