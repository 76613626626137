.sub-td {
  padding: 5px 0 !important;
  background-color: #eeeeee;
}

table.sub-table {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9 !important;
}

table.sub-table .sub-element {
  padding: 21px 15px !important;
  position: relative;
  top: 12px;
  background-color: transparent;
  border: none;
  border-left: 2px solid #ccc;
  margin-left: 20px;
  border-radius: 0 !important;
  opacity: 1;
  height: 31px !important;
}

table.sub-table .sub-element .fa {
  margin-left: -22px;
  margin-right: 5px;
  color: #ccc;
}

table.sub-table .sub-add .fa {
  margin-left: -22px;
  margin-right: 5px;
  color: #efefef;
}

table.sub-table .sub-add {
  padding: 21px 15px !important;
  position: relative;
  background-color: transparent;
  border: none;
  border-left: 2px solid #efefef;
  margin-left: 20px;
  border-radius: 0 !important;
  opacity: 1;
  height: 31px !important;
}

table.sub-table tr td {
  background-color: #f9f9f9 !important;
  height: 41px !important;
}

table.sub-table tr {
  background-color: #f9f9f9 !important;
}
