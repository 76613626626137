.modify-treenode-edit-window {
    .content-modify-treenode {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .form-group {
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        background: white;
    }

    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: $primary-color;
        color: #ffffff;
    }

    p-multiselect {
        .p-multiselect {
            width: 40rem;
        }
    }

    p-dropdown {
        .p-dropdown {
            width: 40rem;
        }
    }

    p-multiselect .p-multiselect-label {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    p-multiselect .p-multiselect-token {
        margin-bottom: 0.5rem;
    }

    label {
        display: block;
        text-align: center;
    }
    .btn-action-modify {
        background: $primary-color;
        border: $primary-color;
        i {
            margin-right: 0.4rem;
        }
    }

    .p-dialog-footer {
        p-button {
            .p-button.p-button-text {
                color: $primary-color;
            }
            .confirm-btn {
                background: $primary-color;
                border: $primary-color;
            }
        }
    }
}

.p-multiselect-header {
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: $hover-color;
        background: $hover-color;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $primary-color;
        background: $primary-color;
    }
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: $primary-color !important;
}
.p-multiselect-items-wrapper {
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #fff;
        background: $primary-color;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: #fff;
        background: $hover-color;
    }
}
