/* You can add global styles to this file, and also import other style files */
.body {
  background-color: #f4f7f7;
}

body {
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 13px;
}
