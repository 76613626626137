.unlogged-pages {
    .page-header {
        text-align: center;
        margin-bottom: 4rem;
        margin-top: 8rem;
        img {
            height: 6rem;
        }
    }
    .page-body {
        @media (max-width: 766px) {
            width: 100%;
        }
        &.shadow {
            -webkit-box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
            -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
            box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
        }
        background: #fff;
        width: 80%;
        margin: 0 auto;
        padding: 2rem;
        border-radius: 5px;
        .title-icon {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h3 {
                font-weight: 300;
                margin-bottom: 1.5rem;
                line-height: 1.5;
                color: #000;
                font-size: 2.1rem;
            }
            button.p-button {
                background-color: transparent;
                border: 0px;
                background: transparent;
                padding: 0px;
                img {
                    width: 2rem;
                }
                &:hover {
                    background-color: transparent !important;
                    background: transparent !important;
                }
            }
        }
        .instructions {
            font-family: "Lato", Arial, sans-serif;
            font-size: 16px;
            line-height: 1.8;
            font-weight: normal;
            color: gray;
            &.click-invalid {
                color: #ff0000;
            }
        }
        form {
            width: 100%;
            .form-group {
                margin-bottom: 2rem;
                display: flex;
                flex-direction: column;
                width: 100%;
                label {
                    font-size: 12px;
                    letter-spacing: 1px;
                    color: #000;
                    font-weight: 700;
                    display: inline-block;
                    margin-bottom: 0.5rem;
                    line-height: 1.8;
                    font-family: "Lato", Arial, sans-serif;
                }
                .p-inputtext:enabled:focus {
                    border-color: #ced4da;
                    box-shadow: none;
                }
                p-password {
                    width: 100%;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    .p-password,
                    input {
                        width: 100%;
                    }
                }
                .password-eye {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    position: relative;
                    i {
                        right: 0.75rem;
                        top: 50%;
                        margin-top: -0.5rem;
                        position: absolute;
                        color: #495057;
                    }
                }

                .ng-invalid.p-password-component div,
                .ng-invalid.p-password-component input {
                    width: 100%;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    margin: 0;
                }
                .p-password-component div,
                .p-password-component input {
                    width: 100%;
                }
                .btn-sign-in {
                    background: $primary-color;
                    border: 1px solid $primary-color;
                    color: #fff;
                    border-radius: 4px;
                    box-shadow: none !important;
                    font-size: 15px;
                    cursor: pointer;
                    padding: 10px 20px;
                    display: inline-block;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 1.5;
                    &:hover {
                        background-color: $hover-color;
                        border: 1px solid $hover-color;
                    }
                }
                .options {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .remember-me {
                        margin-bottom: 2rem;
                        align-items: center;
                        display: flex;
                        label {
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: 500;
                            white-space: nowrap;
                            padding-left: 1rem;
                            line-height: 1.8;
                            color: $primary-color;
                            margin-bottom: 0rem;
                        }
                        .p-checkbox .p-checkbox-box.p-highlight {
                            border-color: $primary-color;
                            background: $primary-color;
                        }
                    }
                    .forgot-password {
                        margin-bottom: 2rem;
                        a {
                            color: $dark-grey;
                            font-size: 1.2rem;
                            white-space: nowrap;
                            text-decoration: none;
                            background-color: transparent;
                            font-family: "Lato", Arial, sans-serif;
                            font-size: 16px;
                            line-height: 1.8;
                            font-weight: normal;
                        }
                    }
                }
                re-captcha {
                    &.ng-invalid:not(form) {
                        border-top-left-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }
                }
            }
        }
    }
    .page-footer {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        p {
            font-family: "Lato", Arial, sans-serif;
            font-size: 16px;
            line-height: 1.8;
            font-weight: normal;
            color: #808080;
        }
    }
}
