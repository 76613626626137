// multi select
.multiselect-admin {
  color: #ccc;
}
.multiselect-admin .c-token {
  background: #aaaaaa !important;
}
.multiselect-admin .pure-checkbox label::before {
  border-color: $primary-color !important;
}
.multiselect-admin .pure-checkbox input[type="checkbox"]:checked + label:before {
  background: $primary-color !important;
}

.multiselect-admin .selected-item {
  background: #fff;
}
