// pagination ngx
.page-item.active .page-link {
  border-color: $primary-color;
  background-color: $primary-color;
}

// Modal
modal-container.modal {
  border: none;
}
.modal-header {
  background-color: $primary-color;
  color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.modal-header button.close {
  color: white;
}

.modal-header {
  h4:before {
    font-family: "Font Awesome 5 Free";
    content: "\f054  ";
    font-weight: 900;
    font-size: 1.2rem !important;
    padding: 0 0 0 5px;
    opacity: 0.3;
  }
}

// Datapicker
.theme-custom .bs-datepicker-head {
  background-color: $primary-color;
}

.theme-custom .bs-datepicker-body table td span.selected {
  background-color: $primary-color;
}

.theme-custom .bs-datepicker-body table td.week span {
  color: $secondary-color;
}

// accordeon
.panel-heading:hover {
  cursor: pointer !important;
}

accordion-group {
  margin-bottom: 3px;
}


.ngx-file-drop__drop-zone{
  border-radius: 4px !important;
  border: 1px dashed $primary-color !important;

}

