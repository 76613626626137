//datatable
table.dataTable {
  table-layout: auto;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #eee;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #ddd;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #ddd;
}

button.action-btn {
  font-size: 1rem;
  margin-left: 5px;
}

span.table-info {
  padding: 6px 8px;
  display: inline-block;
  font-size: 1.2rem;
  background: none;
}

button.action-btn:disabled {
  opacity: 0.2;
}

.dataTables_info {
  font-size: 1rem;
  padding: 5px 0px;
  opacity: 0.5;
}

.dataTables_filter input {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px;
}
.dataTables_filter ::placeholder {
  opacity: 0.3;
}

// Datatables pagination
#table_paginate,
#table2_paginate {
  margin: 20px 0;
}

#table_paginate a,
#table2_paginate a,
#table_paginate .ellipsis,
#table2_paginate .ellipsis {
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin: 0 !important;
  color: $primary-color !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd !important;
  border-right: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

#table_paginate a:hover,
#table_paginate a:active,
#table_paginate a:focus,
#table2_paginate a:hover,
#table2_paginate a:active,
#table2_paginate a:focus {
  z-index: 2 !important;
  color: $primary-color !important;
  background: #eee !important;
  border-color: #ddd !important;
}

#table_paginate .paginate_button.previous,
#table2_paginate .paginate_button.previous {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

#table_paginate .paginate_button.next,
#table2_paginate .paginate_button.next {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-right: 1px solid #ddd !important;
}

#table_paginate .paginate_button.next:hover,
#table_paginate .paginate_button.next:active,
#table_paginate .paginate_button.next:focus,
#table2_paginate .paginate_button.next:hover,
#table2_paginate .paginate_button.next:active,
#table2_paginate .paginate_button.next:focus {
  border-right: 1px solid #ddd !important;
}

#table_paginate .paginate_button.previous:hover,
#table_paginate .paginate_button.previous:active,
#table_paginate .paginate_button.previous:focus,
#table2_paginate .paginate_button.previous:hover,
#table2_paginate .paginate_button.previous:active,
#table2_paginate .paginate_button.previous:focus {
  border-left: 1px solid #ddd !important;
}

#table_paginate .paginate_button.current:hover,
#table_paginate .paginate_button.current:active,
#table_paginate .paginate_button.current:focus,
#table2_paginate .paginate_button.current:hover,
#table2_paginate .paginate_button.current:active,
#table2_paginate .paginate_button.current:focus {
  color: #ffffff !important;
}

#table_paginate .paginate_button.current,
#table2_paginate .paginate_button.current {
  color: #ffffff !important;
  cursor: default !important;
  background: $primary-color !important;
  border-color: $primary-color !important;
}

#table_paginate .disabled,
#table_paginate .disabled:hover,
#table_paginate .disabled:active,
#table_paginate .disabled:focus,
#table2_paginate .disabled,
#table2_paginate .disabled:hover,
#table2_paginate .disabled:active,
#table2_paginate .disabled:focus {
  color: #777 !important;
  cursor: not-allowed;
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  border-right: none !important;
  border-radius: 0 !important;
}

// SubTables
table.sub-table .sub-add .ui-autocomplete {
  width: 50%;
  margin-right: 5px;
}

// Search Box
.search-form [type="text"] {
  border: 1px solid #ccc;
}

.search-submit {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 35px !important;
  padding: 5px 14px !important;
}

.ui-autocomplete {
  width: 100%;
}

.ui-autocomplete-input {
  display: block;
  width: 100% !important;
  height: 34px;
  padding: 6px 12px;
  font-size: 1.2rem;
  line-height: 1.42857143;
  background-image: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  box-shadow: none;
}

