@import './variables.scss';

.search-form {
    width: 328px;
    border-radius:3px;
    padding: 15px 0px;

}

.search-form [type="text"] {
    height: 35px;
    padding: 5px 14px;
	float:left;
    border-radius: 3px 0 0 3px;
    border: 1px solid $primary-color;
}
.search-form [type="submit"] {
    background: none repeat scroll 0 0 $primary-color;
    border: medium none;
    color: #FFFFFF;
    cursor: pointer;
    height: 35px;
    padding: 5px 14px;
	text-transform:uppercase;
	border-radius: 0 3px 3px 0;
}
.search-form input[type="submit"]:hover {
  background-color: $hover-color;
}

