.admin-module {
  overflow: inherit !important;
}

.clear {
  clear: both;
}

// header page
.well {
  background-color: #fff;

  h1 {
    color: white;
    font-size: 1.2rem !important;
    margin: -2rem -2rem 1.8rem -2rem;
    padding: 1.8rem;
    background-color: $primary-color;
    border-bottom: 1px solid rgb(227, 227, 227);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-color: $primary-color;
    text-transform: uppercase;
  }

  h1:before {
    font-family: "Font Awesome 5 Free";
    content: "\f054  ";
    font-weight: 900;
    font-size: 1.2rem !important;
    padding: 0 0 0 0.5rem;
    opacity: 0.3;
  }
  &-scrollable {
    h1 {
      margin: -2rem -0rem 1.8rem 0rem;
    }
  }
}

// default margins
.d-margin-top {
  margin-top: 1.8rem;
}
.d-margin-bottom {
  margin-bottom: 1.8rem;
}

// btn on pages new element
.create-button {
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
}
