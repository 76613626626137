@import "./variables.scss";
@import "./searchbox.scss";
@import "./inputs.scss";
@import "./forms.scss";
@import "./layout-page.scss";
@import "./ngx-override.scss";
@import "./datatable-override.scss";
@import "./multiselect.scss";
@import "./table-sub-element.scss";

// font styles
.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

// forms
input[type="checkbox"] {
  margin-left: 5px;
  margin-top: 5px;
}

.form-group {
  textarea {
    resize: vertical;
  }
}

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid #42a948; /* green */
}

.ng-invalid:not(form) {
  border-radius: 4px;
  border-left: 5px solid #a94442; /* red */
}
p-dropdown {
  .p-dropdown {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &.ng-invalid {
    .p-dropdown {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin: 0;
    }
  }
}

/*Change Boostrap look of primary button*/
.btn-primary {
  color: #fff;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.btn-primary:hover,
.btn-primary:active {
  color: #fff;
  background-color: $hover-color !important;
  border-color: $hover-color !important;
}

/*Change Boostrap look of stripped table*/
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}

.p-datatable {
  .p-datatable-loading-overlay {
    background-color: transparent;
  }
}

.dataTable td,
.sub-table td {
  vertical-align: middle;
}

/*Fix pagination alignement*/
pagination {
  margin-top: 15px;
}

/*Fix autocomplete template*/
.p-autocomplete-panel {
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
}

.p-autocomplete-input {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  vertical-align: middle;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin: 2px !important;
  position: relative;
  top: 2px;
}

/*fix currencies buttons*/
button.p-ripple.p-button.p-component.p-button-icon-only {
  color: #fff !important;
  background-color: $primary-color !important;
  border-color: $primary-color;
  border: none;
  border-radius: 0.25rem;
  margin-top: 2px;
}

button {
  a {
    color: #fff;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

button.p-button:hover,
button.p-button:active {
  color: #fff !important;
  background-color: $hover-color !important;
  border-color: $hover-color !important;
  &.p-button-danger {
    &:hover {
      color: #ffffff;
      border: 1px solid #a21818 !important;
    }
  }
  &.p-button-warning {
    &:hover {
      background-color: #e0a800 !important;
      color: #212529 !important;
      border-color: #d39e00 !important;
    }
  }
}

/*badge fixes*/
.badge.bg-secondary {
  color: white !important;
}

/*breadcrumb dropdown */
.breadcrumb-item {
  .dropdown-menu {
    li.active {
      background-color: #c5e1f5 !important;
    }
    li {
      padding-left: 5px;
    }
  }

  .fa {
    align-items: center;
  }
}

/*Custom spinner*/
.custom-spinner .p-progress-spinner-circle {
  stroke: $primary-color !important;
}

/*custom prime list picker*/
.p-picklist-list {
  height: 400px;
  max-height: 400px;
}

/*custom prime multiselect chip*/
p-multiselect {
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background-color: #b1b1b1;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: unset;
    color: black;
  }
}

.table-pagination {
  &-scrollable {
    overflow-x: auto;
  }
  &__padding {
    padding-left: 0.75rem;
  }
}

.p-button {
  &.p-button-success {
    background: $primary-color !important;
  }
  &.p-button-danger {
    &:hover {
      background: #a21818 !important;
    }
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $primary-color !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $primary-color !important;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  background: $primary-color !important;
}
