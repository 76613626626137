@import './usevariables.scss';

.star{
    color: $danger-color;
    padding-left: 3px;
}

@media (min-width: $screen-md-min){
    .form-horizontal{
        margin-left: 10px;
        .control-label{
            padding-top: 12px !important;
        }
        code{
            margin-top: 8px;
            display: inline-block;
        }
    }
}
